import React from 'react';
import Display from '../components/Display';

const Home = () => {
  return (
    <div className=''>
      <Display />
    </div>
  );
};

export default Home;
