import React from 'react';
import ReservationForm from './ReservationForm';

const Reserve = () => {
  return (
    <>
      <h1> Add Reservation</h1>
      <ReservationForm />
    </>
  );
};

export default Reserve;
